.legalfooter {
  z-index: 20;
  display: flex;
  justify-content: flex-end;
  padding: 0 1em;
  height: 1.2em;
  background-color: rgb(242, 242, 242);
  >a {
    color: black;
    margin: 0 0.5em
  }
}

.absolute {
    position: absolute;
    bottom: 0;
    width: 100%;
}
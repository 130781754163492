.avatar {
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-radius: 1000px;
  display: inline-block;
  vertical-align: middle;
}

.isBeingFollowed {
  border-color: rgba(255, 255, 255, 0.25);
}

.size-1x {
  width: 1em;
  height: 1em;
}

.size-2x {
  width: 2em;
  height: 2em;
}

.size-3x {
  width: 3em;
  height: 3em;
}

.size-4x {
  width: 4em;
  height: 4em;
}

.size-5x {
  width: 5em;
  height: 5em;
}

.size-6x {
  width: 6em;
  height: 6em;
}

.size-7x {
  width: 7em;
  height: 7em;
}

.size-10x {
  width: 10em;
  height: 10em;
}

.root {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 0.7em;
}

.feeling {
  width: 30px;
  height: 30px;
  opacity: 0.25;
  transition: all 1s ease;
  cursor: pointer;
  flex-grow: 0;

  &:hover {
    opacity: 1;
  }
}

.current {
  opacity: 1;
}

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-direction: column;

  .item {
    margin-top: 1em;
    max-width: 50%;
    text-align: center;
  }
}

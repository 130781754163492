.root {
}

.icon {
  display: inline-block;
  vertical-align: middle;
}

.comment {
  font-style: italic;
}

:root {
  --ib-main: #154667;
  --ib-main-light: #2373a9;
  --ib-hightlight-color: #62b3e4;
  --eb-button-danger: rgba(194, 46, 46, 1);
  --eb-button-danger-dark: rgba(194, 46, 46, 1);
  --eb-button-danger-light: rgba(194, 46, 46, 1);
  --eb-button-highlight: rgba(110, 179, 227, 1);
  --eb-button-highlight-dark: rgba(110, 179, 227, 1);
  --eb-button-highlight-light: rgba(110, 179, 227, 1);
  --eb-button-main: rgba(20, 69, 102, 1);
  --eb-button-main-light: rgba(20, 69, 102, 1);
  --eb-button-main-dark: rgba(20, 69, 102, 1);
  --eb-button-success: rgba(69, 163, 54, 1);
  --eb-button-success-light: rgba(69, 163, 54, 1);
  --eb-button-success-dark: rgba(69, 163, 54, 1);
  --eb-layer-background-active-color: rgba(110, 179, 227, 1);
  --eb-layer-background-color: rgba(255, 255, 255, 1);
  --eb-layer-icon-active-color: rgba(255, 255, 255, 1);
  --eb-layer-icon-color: rgba(110, 179, 227, 1);
  --eb-link-highlight-color: rgba(110, 179, 227, 1);
  --eb-popup-chart-line-color: rgba(110, 179, 227, 1);
  --eb-popup-header-background-color: rgba(110, 179, 227, 1);
  --eb-popup-header-title-color: rgba(255, 255, 255, 1);

  --eb-notification-info-bg-color: rgba(76, 179, 238, 1);
  --eb-notification-info-color: rgba(255, 255, 255, 1);
  --eb-notification-warning-bg-color: rgba(238, 194, 76, 1);
  --eb-notification-error-bg-color: rgba(222, 88, 93, 1);
  --eb-notification-error-color: rgba(255, 255, 255, 1);

  --eb-text-gray: #808080;
}

:global {
  ::selection {
    background: #bfedff;
  }

  html,
  body {
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 0;
    line-height: 1.15;
    overflow: hidden;

    @media print {
      overflow: auto;
      height: auto;
      width: auto;
    }

    .Toastify__toast--info {
      background-color: var(--eb-notification-info-bg-color);
      color: var(--eb-notification-info-color);
    }
    .Toastify__toast--warning {
      background-color: var(--eb-notification-warning-bg-color);
    }
    .Toastify__toast--error {
      background-color: var(--eb-notification-error-bg-color);
      color: var(--eb-notification-error-color);
    }
    .Toastify__toast-container--top-center {
      position: relative;
    }
  }

  h3 {
    display: block;
    font-size: 1.17em;
    margin-block: 1em;
    font-weight: bold;

    @supports not (margin-block: 1em) {
      margin-top: 1em;
      margin-bottom: 1em;
    }
  }

  p {
    display: block;
    margin-block: 1em;
    margin-inline: 0;

    @supports not (margin-block: 1em) {
      margin-top: 1em;
      margin-bottom: 1em;
    }

    @supports not (margin-inline: 0) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  html {
    font-family: "Source Sans Pro", serif;
    font-size: 12px;

    @media (min-width: 640px) and (min-height: 480px) {
      font-size: 14px;
    }

    @media (min-width: 1440px) and (min-height: 900px) {
      font-size: 16px;
    }
  }

  .react-date-picker__wrapper {
    align-items: center;
  }

  #app {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    margin: 0;
    overflow: auto;
  }

  #foo {
    display: none;
  }
}

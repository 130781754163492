.legend {
  float: right;
}

.table {
  border-collapse: collapse;
  width: 100%;
  font-size: 0.8em;

  th {
    border: 1px solid #d1d3d4;
    text-align: left;
    padding: 8px;
    color: #58595b;
    background-color: #f1f2f2;
    font-weight: lighter;
    text-transform: uppercase;
  }

  td {
    border: 1px solid #d1d3d4;
    text-align: left;
    padding: 8px;
    font-weight: normal;
  }

  td[colspan="5"] {
    padding: 8px 0;
    background-image: -webkit-linear-gradient(
        0deg,
        transparent,
        transparent calc(100% / 5),
        rgba(241, 242, 242, 0.5) calc((100% / 5) + 1px),
        transparent calc((100% / 5) + 1px)
      ),
      -webkit-linear-gradient(0deg, transparent, transparent
            calc((100% / 5 * 2) - 1px), rgba(241, 242, 242, 0.5)
            calc(100% / 5 * 2), transparent calc(100% / 5 * 2)),
      -webkit-linear-gradient(0deg, transparent, transparent calc(100% / 5 * 3), rgba(
              241,
              242,
              242,
              0.5
            )
            calc((100% / 5 * 3) + 1px), transparent calc((100% / 5 * 3) + 1px)),
      -webkit-linear-gradient(0deg, transparent, transparent
            calc((100% / 5 * 4) - 1px), rgba(241, 242, 242, 0.5)
            calc(100% / 5 * 4), transparent calc(100% / 5 * 4)),
      -webkit-linear-gradient(0deg, transparent, transparent calc((100%) + 1px), rgba(
              241,
              242,
              242,
              0.5
            )
            calc(100%), transparent calc(100%));
    background-image: linear-gradient(
        90deg,
        transparent,
        transparent calc(100% / 5),
        rgba(241, 242, 242, 0.75) calc((100% / 5) + 1px),
        transparent calc((100% / 5) + 1px)
      ),
      linear-gradient(
        90deg,
        transparent,
        transparent calc((100% / 5 * 2) - 1px),
        rgba(241, 242, 242, 0.75) calc(100% / 5 * 2),
        transparent calc(100% / 5 * 2)
      ),
      linear-gradient(
        90deg,
        transparent,
        transparent calc(100% / 5 * 3),
        rgba(241, 242, 242, 0.75) calc((100% / 5 * 3) + 1px),
        transparent calc((100% / 5 * 3) + 1px)
      ),
      linear-gradient(
        90deg,
        transparent,
        transparent calc(100% / 5 * 4),
        rgba(241, 242, 242, 0.75) calc((100% / 5 * 4) - 1px),
        transparent calc((100% / 5 * 4) + 1px)
      ),
      linear-gradient(
        90deg,
        transparent,
        transparent calc((100%) + 1px),
        rgba(241, 242, 242, 0.75) calc(100%),
        transparent calc(100%)
      );
    background-size: 100%;
    background-repeat: repeat;
  }

  td[colspan="5"] {
    display: table-cell;
  }

  th:nth-child(5),
  th:nth-child(6),
  th:nth-child(7),
  th:nth-child(8),
  th:nth-child(9) {
    display: table-cell;
  }
}

.message {
  padding: 0.5em;
  border: 1px solid #000000;
  margin-bottom: 1em;
  border-radius: 3px;
}

.error {
  background-color: RGB(241, 222, 222);
  border-color: RGB(234, 204, 209);
  color: RGB(167, 69, 68);
}

.success {
  background-color: RGB(223, 239, 217);
  color: RGB(62, 117, 63);
  border-color: RGB(214, 232, 199);
}

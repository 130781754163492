.root {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
}

.message {
  position: relative;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
  width: 320px;
  height: 320px;
  background: #90d6f1;
  border-radius: 3em;
}

.flexor {
  margin: 1em;
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.error {
  text-align: center;
  color: #ffffff;
}

.root {
  color: #616161;

  &:hover,
  &:active {
    color: var(--eb-link-highlight-color);

    svg {
      fill: var(--eb-link-highlight-color);
    }
  }
}

.disabled {
  pointer-events: none;
  text-decoration: none;
}

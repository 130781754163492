.title {
  margin: 0 0 1em 0;
  text-align: center;
  overflow: hidden;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.links {
  text-align: center;
  margin: 1em 0 0 0;
  overflow: hidden;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.status {
  font-style: italic;
  margin: 0 0 1em 0;
  text-align: center;
}

.root {
  padding: 0.5em 1em;
  display: flex;
  flex: 1 1 100%;

  a:link,
  a:visited,
  a:hover,
  a:active {
    color: rgb(255, 255, 255);
  }
}

.ack {
  margin-left: 0.25em;
  cursor: pointer;
  align-self: center;
  text-align: right;
  width: 150px;
}

.iconContainer {
  align-self: center;
}

.icon {
  margin-right: 0.25em;
}

.acker {
  margin-left: 0.25em;
}

.errore {
  align-self: center;
  width: 100%;
}

.disconnect {
  background-color: rgba(128, 128, 128, 1);
  color: rgba(255, 255, 255, 1);
}

.info {
  background-color: var(--eb-notification-info-bg-color);
  color: var(--eb-notification-info-color);
}

.warning {
  background-color: var(--eb-notification-warning-bg-color);
}

.danger {
  background-color: var(--eb-notification-error-bg-color);
  color: var(--eb-notification-error-color);
}

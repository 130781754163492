.root {
  background-color: rgb(222, 222, 222);
  font-style: italic;
  color: rgb(200, 200, 200);
}

.current {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  font-style: normal;
}

.edit_mode {
  cursor: pointer;
}

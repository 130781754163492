.root {
  padding: 0.5em 0.6em;
  font-size: inherit;
  color: #000000;
  font-weight: normal;
  background-color: #f9f9f9;
  border: 2px solid #edeff1;
  border-radius: 6px;
  transition: border 0.25s linear 0s, color 0.25s linear 0s,
    background-color 0.25s linear 0s;

  &:focus {
    border: 2px solid #62b3e4;
    outline: 0;
  }

  &[disabled] {
    cursor: not-allowed;
    color: #8e8e8e;
    background: #edeff1;
  }
}

.inherit {
  width: inherit;
}

.width {
  width: 100%;
}

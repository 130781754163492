.root {
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  align-items: stretch;
  align-content: stretch;
  flex-basis: 100%;
  min-width: 0;

  .center {
    align-self: center;
  }

  .full {
    flex-basis: 100%;
  }
}

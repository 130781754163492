.root {
  border: 0;
  background-color: var(--eb-button-main);
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  padding: 0.5em;
  font-family: inherit;
  margin-right: 0.5em;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover,
  &:focus,
  &:active {
    outline: 0;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.block {
  width: 100%;
  display: block;
  margin-right: 0;
  margin-bottom: 0.5em;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.primary {
  &:hover,
  &:focus,
  &:active {
    background: var(--eb-button-main-light);
  }
}

.secondary {
  color: var(--eb-button-main);
  background-color: rgb(255, 255, 255);
  &:hover,
  &:focus,
  &:active {
    background: #d9d9d9;
  }
}

.danger {
  background-color: var(--eb-button-danger);
  &:hover,
  &:focus,
  &:active {
    background-color: var(--eb-button-danger-dark);
  }
}

.tertiary {
  color: rgb(255, 255, 255);
  background-color: var(--eb-button-highlight);
  &:hover,
  &:focus,
  &:active {
    background: var(--eb-button-highlight-dark);
  }
}

.success {
  color: rgb(255, 255, 255);
  background-color: var(--eb-button-success);
  &:hover,
  &:focus,
  &:active {
    background: var(--eb-button-success-dark);
  }
}

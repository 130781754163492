.root {
  h2 {
    font-size: 1.2em;
  }

  h3 {
    font-size: 0.8em;
  }
}

.icon {
  display: inline-block;
  vertical-align: middle;
}

.select {
  margin-bottom: 1em;
}

.comment {
  font-style: italic;
}

.description {
  resize: none;
  font-family: inherit;
  height: 8em;
  margin-bottom: 0.7em;
}

.error {
  color: #ff0000;
  margin-top: 0;
}

.guide {
  color: #4cb3ee;
}

.form {
  display: flex;
  flex-direction: column;
}

.checkboxContainer {
  display: flex;
  margin-bottom: 10px;
}

.checkbox {
  display: flex;
  justify-content: center;
  align-self: center;
  cursor: pointer;
}

.checkboxLabel {
  color: var(--eb-text-gray);
}

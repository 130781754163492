.root {
  vertical-align: middle;
}

.block {
  display: block;
}

.inline_block {
  display: inline-block;
}

.padding {
  padding: 1em;
}

.no_padding {
  padding: 0;
}

@keyframes pulse {
  0% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1.1);
  }
}

.pulseAnimation {
  svg {
    animation: pulse 1.5s linear infinite;
  }
}

.root {
}

.icon {
  display: inline-block;
  vertical-align: middle;
}

.comment {
  font-style: italic;
}

div.previewContainer {
  max-width: 100px;
}

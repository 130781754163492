.root {
  padding: 0.75em;
  flex-basis: 100%;
  min-width: 0;

  .right {
    text-align: right;
  }

  .center {
    text-align: center;
  }
}

.splitter {
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.left {
  align-self: center;
  text-align: right;
}

.right {
  align-self: center;
  text-align: left;
}

.table {
  th {
    text-align: right;
  }
}

.stora {
  margin: 0.5em 0;
  font-size: 2em;
}

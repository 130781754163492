.img {
  display: block;
  width: auto;
  height: 100%;
  width: 100%;
}

.thumb {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  max-width: 300px;
  overflow: hidden;
  padding: 4px;
  box-sizing: border-box;
  cursor: pointer;
}

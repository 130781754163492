.ticket {
}

.active {
  background-color: RGBA(0, 0, 0, 0.2);
}

.loadMore {
  margin: 1em 0;
  text-align: center;

  > div {
    margin-top: 2em;
  }
}

.root {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.item {
  margin: 0.1em 0;
}

.icon {
  margin-right: 0.2em;
}

.root {
  background-color: RGB(56, 114, 164);
  padding: 0.33em;
  color: rgb(255, 255, 255);
  display: inline-block;
  border-radius: 3px;
  margin: 0;
  border: 0;
  cursor: pointer;
  width: 5em;
  text-align: center;
}

.upvoted {
  background-color: rgb(71, 164, 56);
  color: rgb(255, 255, 255);
}

.upvotes {
  display: inline-block;
  margin-left: 0.5em;
  font-size: smaller;
}

.root {
  margin: 0.25em 0;

  &.large {
    margin: 1em 0;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.center {
  text-align: center;
}

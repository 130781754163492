.links {
  text-align: center;
}

.back {
  margin-bottom: 1em;
}

.box {
  width: 250px;

  @media (min-width: 425px) {
    width: 300px;
  }
}

.width {
  width: 100%;
}

.thumb {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  max-width: 100px;
  width: 100px;
  max-height: 100px;
  padding: 4px;
  box-sizing: border-box;
}

.dropzone {
  width: 100%;
  margin-bottom: 1em;
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cancel {
  cursor: pointer;
}

.thumbInner {
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
}

.img {
  display: block;
  width: auto;
  height: 100%;
}

.selectedTitle {
  margin: 1em 0 0 0;
}

.thumbsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

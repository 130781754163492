.root {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: stretch;
  align-content: stretch;
}

.inline {
  margin: 0.75em;
}

.bordered {
  border: 1px solid rgb(204, 204, 204);
}

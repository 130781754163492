.root {
  display: inline-block;
  font-size: 0.8em;
  padding: 0.3em 0.3em;
  border-radius: 2px;
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  background-color: var(--eb-button-highlight);
  color: rgb(255, 255, 255);

  &:last-of-type {
    margin-right: 0;
  }
}

.width {
  width: 100%;
}

.terms_content {
  display: flex;
}

.terms_checkbox {
  margin-right: 0.5em;
  width: 24px;
}

.root {
  margin: 1em 0;
  word-wrap: break-word;

  &:first-of-type {
    margin-top: 0;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.container {
  display: flex;
  flex-direction: column;
  background-color: #62b3e4;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin: 0 0;
  /* New linear gradient. Not in use yet
   * background: linear-gradient(to bottom right, rgb(106,206,245), rgb(181,219,173));*/
}

.box {
  background-color: rgb(230, 231, 232);
  max-width: 310px;
  padding: 1.5em;
  border-radius: 0;
  order: 2;
  margin: 0 1em;

  h1 {
    font-size: 18px;
    margin: 0 0 1em 0;
  }
}

.logoContainer {
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 0.5em;
}

.logo {
  display: inline-block;
  vertical-align: middle;
  width: 11em;
  font-weight: bold;
}

.ib {
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.5em;
}

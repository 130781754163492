.root {
  max-width: 600px;
  /** Only support printing in Chrome, this webkit property makes sure bg colors are included in print*/
  -webkit-print-color-adjust: exact;

  .tooltip {
    width: 100%;
  }

  .container {
    width: 100%;
    height: 30px;
    background-color: rgb(250, 250, 250);
    display: inline-flex;
  }

  .color_square {
    display: inline-block;
    height: 30px;
    position: relative;
  }

  .active {
    background-color: rgb(240, 75, 89);
  }

  .inactive {
    background-color: rgb(210, 210, 210);
  }
}

.div {
  display: inline-block;
  height: 30px;
  position: relative;
}
